<template>
  <div
    id="mentions_legales_poplab_fr"
    class="text-regular"
  >
    <p class="bloc bold">
      Date&nbsp;: 20/09/2021
    </p>

    <div class="bloc">
      <h2 class="s4 light">
        1&nbsp;: Éditeur
      </h2>
      <p>
        Le présent site
        <a
          :href="urlPopLab"
          target="_blank"
          rel="noreferrer noopener"
        >
          {{ urlPopLab }}
        </a>
        (ci-après, le "Site") est édité par la SARL Artemis, nom commercial LDE au capital de
        500&nbsp;000,00&nbsp;€, immatriculée au Registre du Commerce et des Sociétés de Strasbourg sous le
        numéro 395 135 999, dont le siège social est situé au 165, rue du Château 67230 WESTHOUSE.
      </p>
      <address>
        Numéro de TVA intracommunautaire&nbsp;: FR 12 395 135 999<br />
        Téléphone&nbsp;: 03 88 39 17 07 - Fax&nbsp;: 03 88 39 04 98<br />
        Adresse courriel&nbsp;: <a href="mailto:aide@poplab.education">aide@poplab.education</a>
      </address>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        2&nbsp;: Directeur de publication
      </h2>
      <p>Le Directeur de la publication est Monsieur Frédéric FRITSCH.</p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        3&nbsp;: Hébergement
      </h2>
      <address>
        L’hébergement du Site est assuré par la société française OVH dont le siège est 2 rue Kellermann 59100 ROUBAIX
        1007, SAS au capital de 10 069 020 €, immatriculé au Registre du Commerce et des Sociétés de Lille Métropole
        sous le numéro 424 761 419 00045.<br />
        Téléphone&nbsp;: +33 3 88 99 02 82<br />
        Site internet&nbsp;:
        <a
          href="https://help.ovhcloud.com/fr/"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://help.ovhcloud.com/fr/
        </a>
      </address>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        4&nbsp;: Réalisation
      </h2>
      <p>Webdesign et développement&nbsp;: LDE</p>
      <p>
        L’accès au Site ainsi que l’utilisation de son contenu s’effectuent dans le cadre des mentions d’utilisation
        décrites ci-après. Le fait d’accéder et de naviguer sur le Site constitue de la part de l’internaute une
        acceptation sans réserve des précisions suivantes.
      </p>
    </div>
  </div>
</template>

<script>
import config from "@/config";

/**
 * Affiche la page des mentions légales de PopLab en français.
 */
export default {
  name: "MentionsLegalesPopLabFr",
  computed: {
    urlPopLab() {
      return config.urlFr.poplab;
    },
  },
};
</script>

<style lang="scss"></style>
