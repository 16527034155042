<template>
  <div id="mentions_legales_poplab">
    <MentionsLegalesPopLabDe v-if="country === 'de'" />
    <MentionsLegalesPopLabFr v-else />
  </div>
</template>

<script>
import MentionsLegalesPopLabFr from "@/components/legal/poplab/MentionsLegalesPopLabFr.vue";
import MentionsLegalesPopLabDe from "@/components/legal/poplab/MentionsLegalesPopLabDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les mentions légales de PopLab dans la bonne langue.
 */
export default {
  name: "MentionsLegalesPopLab",
  components: {
    MentionsLegalesPopLabFr,
    MentionsLegalesPopLabDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
